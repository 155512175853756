import dynamic from 'next/dynamic';

// Dynamically import components with loading fallbacks
export const LazyProductCarousel = dynamic(() => import('./ProductCarousel'), {
  loading: () => <div className='animate-pulse h-[300px] bg-gray-100 rounded-lg' />,
});

export const LazyCategoriesCarousel = dynamic(() => import('./CategoriesCarousel'), {
  loading: () => <div className='animate-pulse h-[200px] bg-gray-100 rounded-lg' />,
});

export const LazyCategoriesGrid = dynamic(() => import('./CategoriesGrid'), {
  loading: () => <div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />,
});

export const LazyFullBanner = dynamic(() => import('./FullBanner'), {
  loading: () => <div className='animate-pulse h-[390px] bg-gray-100 rounded-lg' />,
});

export const LazySingleBanner = dynamic(() => import('./SingleBanner'), {
  loading: () => <div className='animate-pulse h-[350px] bg-gray-100 rounded-lg' />,
});

export const LazyRichText = dynamic(() => import('./RichText'), {
  loading: () => <div className='animate-pulse h-[200px] bg-gray-100 rounded-lg' />,
});

export const LazySlider = dynamic(() => import('./Slider'), {
  loading: () => <div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />,
});

export const LazyProductGrid = dynamic(() => import('../ProductGrid'), {
  loading: () => <div className='animate-pulse h-[400px] bg-gray-100 rounded-lg' />,
});
